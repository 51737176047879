<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'PerfFirstDeliveryDrop',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Alert level',
          value: (alert) => {
            return alert.data.no_respect && alert.data.no_respect.length
              ? this.$commonUtils.htmlEntities(alert.data.no_respect[0].lvl)
              : 'NC'
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        },
        {
          label: 'Delivery yesterday vs delivery the day before',
          value: (alert) => {
            if (alert.data.no_respect && alert.data.no_respect.length && alert.data.no_respect[0].drop) {
              return this.$commonUtils.htmlEntities(alert.data.no_respect[0].drop) + '%'
            }
            return 'NC'
          },
          endLabel: '',
          roundValue: false,
          isPerc: false,
          colorize: false,
          isDate: false
        }
      ],
      settingsData: [],
      algoStrategyData: [
        { label: 'Perf_first', value: 'data.IO_is_perf_first', endLabel: '', roundValue: false, isPerc: false, colorize: false },
        { label: 'Limiting KPI_target', value: 'data.limiting_KPI_target', endLabel: '', roundValue: true, isPerc: true, colorize: false }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
